@mixin flex-column-count ($columns, $v-align: flex-start) {
  // Use this to create custom column counts in single column layout containers.
  // Usage: Set layout container to single column then add one of the flx-col-# classes 
  // to the containers custom classes
  //
  .column {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: $v-align;
    .pageEl {
      flex-basis: calc(100% / #{$columns});
    }
  }
}

.user_mode {
  .flx-col-3 {
    @include flex-column-count (3);
  }
  .flx-col-4 {
    @include flex-column-count (4);
  }

  .flx-col-5 {
    @include flex-column-count (5);
  }

  .flx-col-6 {
    @include flex-column-count (6);
  }

  .flx-col-7 {
    @include flex-column-count (7);
  }

  .flx-col-8 {
    @include flex-column-count (8);
  }
}
