.user_mode {
  .champion-block-item {
    //position: absolute;
    //right: 25px;
    //order: 2;
    line-height: 1;
    display: flex;
    flex-flow: row;
    align-items: center;
		font-style: italic;
		min-width: 350px;
		height:125px;
		pointer-events: all;
    //margin-left: 25px;
    //margin-right: 25px;
    .text {
      font-family: $fontOne;
			order: 0;
			padding-left:25px;
			transition: margin .2s ease;
			a {
				color:#000;
				&:hover{
					text-decoration: none;
				}
			}
      p {
        font-size: 12px;
        line-height: 1.2;
        margin: 0;
      }
      h5 {
        line-height: 1.2;
        font-weight: normal;
      }
    }
    [class *="TextImage"] {
			display:flex;
			align-items:center;
			height: 125px !important;
      width: auto !important;
      min-height: initial !important;
			order: 1;
			padding-right:25px;
      img {
        max-height: 70px;
        width: auto !important;
        transition: height .2s ease;
      }
    }
  }

}

// .nav-fixed {
//   .champion-block-item {
//     top: -10px;
//     .text {
//       margin-top: -10px;
//       p {
//         font-size: 11px;
//       }
//       h5 {}
//     }
//     [class *="TextImage"] {
//       img {
//         max-height: 55px;
//       }
//     }
//   }
// }
.has-mobile-nav {
  .user_mode {
    .champion-block-item {
      padding-top: 20px;
      justify-content: center;
    }
  }
}

.edit_mode {
  .champion-block-item {
    display: none;
  }
}
