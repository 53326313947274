.user_mode {

  .parallax-container {
    height: 425px;
    background-attachment: fixed;
    background-position: center 120px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
		position: relative;
		transform: translate3d(0,0,0);
    .column {
      z-index: 1;
    }
    .textBlockElement {
      h3 span {
        color: #fff;
        font-size: 90px;
        font-style: italic;
        font-weight: 400;
        text-transform: uppercase;
        .has-mobile-nav & {
          font-size: 55px;
        }
      }
      .text {
        color: #fff;
        font-size: 16px;
        p {
          margin-bottom: 1.5rem;
        }
        a:last-of-type {
          color: #fff;
          background: $primary-color;
          text-decoration: none;
          font-size: 14px;
          font-style: italic; //text-transform: uppercase;
          padding: 12px 30px 12px 25px;
          transition: background-color .2s linear;
          &:hover {
            background-color: $hover-color;
          }
        }
      }
      .originalTextImage {
        display: none;
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      @include darkOverlayfromLeft();
      margin-top: 0;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }

  }

}

.has-mobile-nav {
  .user_mode {
    .parallax-container {
			background-attachment: scroll;
			height: 425px;
			background-position: center;
			background-size: cover;
			transform: unset;
    }
  }
}

/* Landscape */
@media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1.5) {

		.user_mode {
			.parallax-container {
				background-attachment: scroll;
				height: 425px;
				background-position: center;
				background-size: cover;
				transform: unset;
			}
		}

}
