$tabs-default-container-baseline-color: transparent; //$secondary-color;
$tabs-item-background-color: $gray-med-2;
$tabs-item-background-color-hover: $primary-color;
$tabs-item-padding: 17px;
$tabs-item-spacing: null; //3px;
$tabs-text-color: #fff;
$tabs-text-color-hover: null;
$tabs-font-family: $fontTwo;
$tabs-font-size: 16px;
$tabs-line-height: 1;
$tabs-font-weight: 700;
$tabs-font-style: null;
$tabs-text-case: none;
$tabs-offset: 0;
$tab-border-base-size: 5px;
$tab-border-base-color: $gray-dark;
$tabs-container-offset: null; //$tabs-item-spacing/2;

@mixin tab-container-styles(){
  // border-bottom: $tab-border-base-size solid $tab-border-base-color;
}
@mixin tab-container-style(){
  // border-bottom: $tab-border-base-size solid $tab-border-base-color;
}


// 1. From Theme
// 2. From Breakpoints
// 3. Default Styles

// 1. From Theme
.pageEl {
  .tabbedElement {
    .tabContainer {
      border: 1px solid $gray-med;
      padding-left: $desktop-padding;
      padding-right: $desktop-padding;
      @media screen and (max-width: $breakpoint-sm-max){
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
      }
      .current {
        // There is an inline width set here.
        // Need to uddate tabElement to remove.
        width: 100% !important;
      }
    }
  }
}

// Edit Mode Fixes
.edit_mode {
  .contentTabs.rapidTabsConstruction {
    li span a {
      color: #666;
      background: transparent;
      border-right: none;
    }

    .selected span a {
      background: #fccd22;
      color: #333;
    }
  }
}

// 2. From Breakpoin
// 3. Default Styles
.contentTabs {

  &:after { clear: both; }
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &.rapidTabsConstruction a.tabLink {
    padding-top: 4px;
    padding-left: 8px;
    display: inline-block;
    background: none;
  }
}

.pageElementAdmin .rosterPlayerStatElement .contentTabs {
  margin: 0;
}

.dataTable .disabled {
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/* Loading Tab Animation
-------------------------------------------------------------------- */
#contentTabsLoader {
  text-align: center;
  clear: both;
  padding: 25px;
}

/* Rapid Tabs Construction
-------------------------------------------------------------------- */
.rapidTabsConstruction {
  background: #fddd40;
  margin-bottom: 0;
  border-top: solid 1px #dda301;
  padding: 0 0 1px;

  li {
    border-right: solid 1px #dda301;
    padding-right: 0;

    a {
      font-size: 14px;
    }

    span {
      background: none;
      padding-left: 0;

      a {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .selected {
    background: #fde872;
  }
}




// ——————————

.pageEl,
#RosterPlayerStats,
.calendarContainer{
  .contentTabs{
    @include tab-container-style;
  }
}
#rapidTabs:not(.rapidTabsConstruction){
  @include tab-container-style;
}

// 3. Default Styles
.contentTabs {
  display: flex;
  flex-flow: row wrap;
  .tab-center & {
    justify-content: center;
    text-align: center;
    li{
      margin: 0 15px;
    }
  }
  list-style-type: none;
  text-align: left;
  width: auto;
  // margin: 10px 0;
  // + .tabContainer {
  //   margin-top: $page-element-margin;
  //   .tab-inset-content & {
  //     margin-top: 0;
  //   }
  // }
  margin-right: -$tabs-container-offset;
  margin-left: -$tabs-container-offset;
  &:after{
    content: "";
    display: block;
    width: 100%;
    margin-right: $tabs-container-offset;
    margin-left: $tabs-container-offset;
    @include tab-container-styles;
  }
  li{
    // margin-top: $tabs-item-spacing;
    // margin-left: #{$tabs-item-spacing/2};
    // margin-right: #{$tabs-item-spacing/2};
    text-align: center;
    @media screen and (max-width: $breakpoint-sm-max){ flex: 1 1 30%; }
    @media screen and (max-width: 530px){ flex: 1 1 48%; }
    @media screen and (max-width: 360px){ flex: 1 1 100%; }
  }
  a{
    display: block;
    padding: $tabs-item-padding;
    color: $tabs-text-color;
    background-color: $tabs-item-background-color;
    font-family: $tabs-font-family;
    font-size: $tabs-font-size;
    line-height: $tabs-line-height;
    font-weight: normal; // $tabs-font-weight;
    font-style: $tabs-font-style;
		text-transform: $tabs-text-case;
		margin-right: 1px;
    //border-bottom: 5px solid transparent;
    transition: color $transition-linear, background-color $transition-linear, border-bottom $transition-linear;
    &:hover{
      //border-bottom: 5px solid $gray-dark;
      color: $tabs-text-color-hover;
      background-color: $tabs-item-background-color-hover;
    }
  }
  .selected a{
    font-weight: $tabs-font-weight;
    color: $tabs-text-color-hover;
    //border-bottom: 5px solid $gray-dark;
    background-color: $tabs-item-background-color-hover;
  }
}

[id="RosterPlayerStats"] .contentTabs {
  // margin: 0 0 0 -2px;
}

.tab-icons{
  .tabbedElement{
    .contentTabs a{
      display: flex;
      flex-flow: column;
      align-items: center;
      &:before{
        content: "";
        font-size: 20px;
        width: 1em;
        height: 1em;
        margin-bottom: .75em;
        flex-shrink: 0;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &[data-title="athletes"]:before{ background-image: url(../images/nav_icons/athletes-gray.svg); }
      &[data-title="players & parents"]:before{ background-image: url(../images/nav_icons/athletes-gray.svg); }
      &[data-title="coaches"]:before{ background-image: url(../images/nav_icons/coaches-gray.svg); }
      &[data-title="officials"]:before{ background-image: url(../images/nav_icons/officials-gray.svg); }
      &[data-title="clubs"]:before{ background-image: url(../images/nav_icons/clubs-gray.svg); }
      &[data-title="competitions"]:before{ background-image: url(../images/nav_icons/comp-gray.svg); }
      &[data-title="membership"]:before{ background-image: url(../images/nav_icons/about-gray.svg); }
    }
  }
}


@mixin tab-acc-tab-container{
  @media screen and (max-width: $breakpoint-sm-max){
    border: 1px solid $gray-dark;
    display: none !important;
    position: static !important;
    width: 100% !important;
    height: auto !important;
    &.active{
      display: block !important;
    }
  }
  @media screen and (min-width: $breakpoint-md-min){
    display: none !important;
    width: 100% !important;
    &.current{
      display: block!important;
      height: auto !important;
      position: relative !important;
      left: 0!important;
    }
  }
}
@mixin tab-acc-tab-title{
  @media screen and (max-width: $breakpoint-sm-max){
    list-style: none;
    a{
      background-color: $gray-dark;
      color: #FFF;
      padding:10px;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      font-family: $tabs-font-family;
      font-size: $tabs-font-size;
      line-height: $tabs-line-height;
      font-weight: 700; // $tabs-font-weight;
      font-style: $tabs-font-style;
      text-transform: $tabs-text-case;
      &:after{
        content: "\f107";
        font-family: FontAwesome;
        display: inline-block;
      }
    }
  }
  @media screen and (min-width: $breakpoint-md-min){
    display: none;
  }
}

// Mobile accordion
.custom-mobile-accordion{
  > .contentTabs{
    @media screen and (max-width: $breakpoint-sm-max){
      display: none;
    }
  }
  .tabContainer{
    > div{
      @include tab-acc-tab-container;
    }
    > li{
      @include tab-acc-tab-title;
      &:active a:after{
        content: "\f106";
      }
    }
    @media screen and (max-width: $breakpoint-sm-max){
      padding-left: 0 !important;
      padding-right: 0 !important;
      border: 0 !important;
      .layoutContainer{
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      > div + li{
        margin-top: 50px;
      }
    }
    @media screen and (min-width: $breakpoint-md-min){
      background-color: transparent;
      > div{
        width: 100% !important;
      }
    }
    > .tab_loader{
      opacity: 1 !important;
    }
  }
}
