.user_mode {
  .layoutContainer.accordion-header {
    padding: 0;

    .pageEl {
      .pageElement {
        margin-top: 0;
        margin-bottom: 0;
        h3 {
          font-size: 20px;
          font-family: $fontTwo;
          font-weight: 700;
          font-style: normal;
          background: $secondary-color;
          color: #fff;
          border-bottom: 1px solid #fff;
          padding: 20px 25px;
          cursor: pointer;
          &:after {
            content: '+';
            text-align: center;
            width: 20px;
            position: absolute;
            right: 30px;

          }
        }
      }
    }

  }
  .accordion-content-container {
    // @include content-area-width;
    // @include content-container-padding;
    // padding-left: 15px;
    // padding-right: 15px;
    background: #fff;
    max-height: 0;
    max-width: $content-area-width;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    transition: max-height .5s ease-in-out, padding .5s ease-in-out;
    .layoutContainer.accordion-content {
      margin: 0;
    }
    &.open {
      padding: 10px;
      max-height: 4000px;
    }
  }
}
