$slider-apsect-ratio-padding: calc(100% / 3);
$slider-text-max-width:750px;
$slider-text-max-width-mobile:320px;
$slider-height:535px;
$slider-height-mobile:127px;
$slider-height-tablet:255px;

.user_mode {
  .home-media-slider {
    .column {
      padding: 0;
    }
    .mediaSlider {
      margin: 0;
      &>p {
        display: none;
      }
    }
    .sn-media-slider {
      height: 0;
      padding-top: $slider-apsect-ratio-padding;
      .slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        .slide-title {
          font-family: $fontOne;
          font-weight: 400;
          font-style: italic;
          font-size: 18px;
          color: $gray-dark;
          text-transform: uppercase;
          text-shadow: none;
          max-width: $slider-text-max-width;
          line-height: 1;
          margin: 5% 0 0 0;
        }
        .slide-description {
          font-family: $fontOne;
          font-weight: 700;
          font-style: italic;
          font-size: 24px;
          overflow-wrap: normal;
          color: $gray-dark;
          text-transform: uppercase;
          text-shadow: none;
          max-width: $slider-text-max-width;
          line-height: .85;
          margin: 0;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: -7px;
            left: 0;
            width: 50px;
            border-bottom: 5px solid $primary-color;
          }
          &:empty:after {
            display: none;
          }
        }
      }
      .slides .slide .media-wrapper {
        img {
          top: 0 !important;
          height: auto !important;
          width: 100% !important;
        }
      } // .slider-pagination-numbers {
      //   z-index: 5;
      //   left: 0;
      //   bottom: 25% !important;
      // }
      .slider-pagination-numbers {
        bottom: 43% !important;
        z-index: 5;
      }

      .slider-pagination-numbers.circular {
        //z-index: 5;
        left: 0; //bottom: 25% !important;
        .paging-item {
          border-radius: 100%;
          height: 10px;
          width: 10px;
          border: 1px solid $secondary-color;
          background: $secondary-color;
          display: inline-block;
          .page-number {
            display: none;
          }

          &.flex-active {
            border: 2px solid white;
            background: $primary-color;
          }
        }
      }
      .slider[class$="-paging"] {
        margin: 0;
      }
      .flex-direction-nav {
        a {
          top: 31%;
        }

        .flex-prev {
          opacity: 1;
          left: 1.5%;
        }
        .flex-next {
          opacity: 1;
          right: 1.5%;
        }
        a:before,
        a:after {
          text-shadow: none;
          font-size: 40px;
          font-weight: 700;
          color: $gray-dark;
        }
      }
    }
  }
}


//Tablet
@media only screen and (min-width: 768px) {
  .user_mode {
    .home-media-slider {
      .sn-media-slider {
        .slider {
          .slide-title {
            font-size: 25px;
          }
          .slide-description {
            font-size: 45px;
            &:after {
              bottom: -14px;
              width: 75px;
              border-bottom: 7px solid $primary-color;
            }
          }
        }
      }
    }
  }
}

// Desktop
@media only screen and (min-width: 1024px) {
  .user_mode {
    .home-media-slider {
      .sn-media-slider {
        .slider {
          .slide-title {
            font-size: 30px;
          }
          .slide-description {
            font-size: 75px;
            &:after {
              bottom: -20px;
              width: 100px;
              border-bottom: 10px solid $primary-color;
            }
          }
        }
      }
    }
  }
}
