$site-footer-padding: 15px;

.snFooterContainer{
  background-color: $gray-dark;
  @media screen and (max-width: $breakpoint-sm-max){
    background-color: #000;
  }
}
.custom-site-footer{
  @include content-area-width;
  @include content-container-padding;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  padding: $site-footer-padding;
  padding-top: 20px;
  padding-bottom: 20px;
  @media screen and (max-width: $breakpoint-sm-max){
    flex-flow: column;
    justify-content: center;
    align-items: center;
    // padding-top: 35px;
    // padding-bottom: 75px;
  }
  // .footer-column-1,
  // .footer-column-3{
  //   max-width: 25%;
  // }
  // .footer-column-2{
  //   display: flex;
  //   flex-flow: row;
  //   justify-content: space-around;
  // }
  // .footer-column-1,
  // .footer-column-2,
  // .footer-column-3{
  //   padding: 15px;
  //   flex-grow: 1;
  // }
  // .footer-column-1{
  //   display: flex;
  //   flex-flow: row;
  //   justify-content: space-between;
  //   align-items: center;
  //   max-width: 249px;
  //   width: 100%;
  //   padding-top: 0;
  //   img{
  //     max-width: 111px;
  //   }
  // }
  // .footer-column-3{
  //   display: flex;
  //   flex-flow: row wrap;
  //   justify-content: flex-start;
  //   > div{
  //     width: 100%;
  //   }
  //   .footer-sponsor{
  //     width: auto;
  //     img{
  //       margin: 0;
  //       width: auto;
  //       height: 50px;
  //     }
  //   }
  // }
  // .footer-logo{
  //   max-width: 115px;
  //   padding: 15px;
  //   img{
  //     &[src*=".svg"]{ height: 100px; }
  //     max-height: 100px;
  //     width: auto;
  //   }
  // }
  // .footer-links{
  //   h3{
  //     color: $gray-light;
  //     font-weight: 900;
  //     font-size: 12px;
  //     margin-bottom: 1em !important;
  //     text-transform: uppercase;
  //   }
  //   p{
  //     color: $gray-light;
  //     font-weight: 700;
  //     font-size: 12px;
  //     margin-bottom: 1em;
  //   }
  //   a{
  //     color: $gray-light;
  //     &:hover{
  //       color: #FFF;
  //     }
  //   }
  //   @media screen and (max-width: $breakpoint-sm-max){
  //     .textBlockElement > h3{
  //       text-align: center !important;
  //     }
  //   }
  //
  // }
  // .footer-social{
  //   font-size: 18px;
  //   display: flex;
  //   flex-flow: row;
  //   justify-content: flex-start;
  //   align-items: center;
  //   align-self: center;
  //   margin-left: auto;
  //   .sn-social-media-list{
  //     &:hover{
  //       .sn-social-media-icon{
  //         opacity: .4;
  //       }
  //     }
  //   }
  //   .sn-social-media-list .sn-social-media-icon{
  //     opacity: 1;
  //     font-size: inherit;
  //     height: auto;
  //     width: 27px;
  //     line-height: 27px;
  //     margin: 0 4px;
  //     padding: 0;
  //     background-color: #FFF;
  //     color: $primary-color;
  //     transition: opacity $transition-linear, background-color $transition-linear;
  //     &:hover{
  //       opacity: 1;
  //       color: $primary-color;
  //       background-color: #FFF !important;
  //     }
  //     &:before{
  //       font-size: inherit;
  //       line-height: inherit;
  //       width: auto;
  //       left: auto;
  //     }
  //   }
  // }
  // .footer-social + .footer-links{
  //   margin-top: 4em;
  // }
  // @media screen and (max-width: $breakpoint-sm-max){
  //   .footer-column-3 .footer-links:first-child{
  //     margin-top: 4em;
  //   }
  //   .footer-column-1,
  //   .footer-column-3,
  //   .footer-social{
  //     justify-content: center;
  //   }
  //   .footer-column-1,
  //   .footer-column-2,
  //   .footer-column-3{
  //     width: 100%;
  //     max-width: 100%;
  //   }
  // }


  [class^="footer-column-"]{
    margin: $site-footer-padding;
    flex-grow: 1;
  }
  .footer-column-1{
    flex: 0 1 115px;
  }
  .footer-column-4{
    flex-grow: 0;
  }
  .textBlockElement{
    > h3 {
      font-size: 18px;
      font-weight: 700;
      &:only-child{
        margin-bottom: .5em;
      }
    }
    .text{
      font-size: 16px;
    }
    ul{
      list-style: none;
      margin: 0;
      column-count: 2;
    }
  }
  .textBlockElement,
  .textBlockElement a{
    color: #FFF;
  }
  hr.hrElement{
    background: #FFF;
    height: 1px;
    max-width: 156px;
    margin: 1rem auto;
  }
  .linkElement h4 a{
    display: block;
    @include global-link-icon-style;
    @include global-link-icon-character($character: "\f041");
    &:after{ order: -1; margin: 0 1ch 0 0; }
  }

}

@media screen and (max-width: $breakpoint-sm-max){
  .custom-site-footer{
    text-align: center;
    .textBlockElement > h3{
      text-align: center !important;
    }
    .footer-column-1{
      max-width: 115px;
    }
    .footer-column-2{
      display: none;
    }
    .footer-column-4{
      width: 100%;
      flex-grow: 1;
      .textBlockElement{
        display: none;
      }
    }
    .linkElement h4 a{
      justify-content: center;
    }
  }
}

.se-footer {
  .privacy-choices:before {
    content: url('../images/privacyoptions.png');
    margin-right: 5px;
  }
}

#siteFooter > ul li {
  margin: 0 5px;
}