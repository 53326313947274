.home.user_mode {

  .signup-container {
    background-color: $secondary-color;

    .column-1 {
      max-width: 600px;
    }

    .ctct-form-embed.form_0 {
      .ctct-form-defaults {
        background-color: transparent;
        .ctct-form-header,
        .ctct-form-text {
          color: #ffffff;
        }
      }
      .ctct-form-custom {
        .ctct-form-label {

          color: #ffffff;

        }
      }
    }
  }
}
