.user_mode {
  .affiliates-container {
    padding-bottom: 30px;
    .column {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-around;
      .pageEl {
        &:not(.band-header) {
          width: 100%;
          max-width: 25%;
        }

        &.band-header {
          flex-basis: 100% !important;
        }
        img {
          width: auto;
          height: auto;
          max-height: 125px;
          max-width: 275px;
          opacity: .4;
          // filter: grayscale(100%);
          filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
          filter: gray; /* IE6-9 */
          -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
          transition: filter .2s linear, opacity .4s linear;
          &:hover {
            filter: grayscale(0);
            opacity: 1;
          }
        }
      }
    }
  }
}

.has-mobile-nav {
  .user_mode {
    .affiliates-container {
      .column {
        flex-flow: column;
        justify-content: center;
        .pageEl {
          &.band-header {
						align-self: flex-start;
						width: 100%;
          }
          &:not(.band-header) {
            max-width: 100%;
          }
          img {
            max-width: 75%;
          }
        }
      }
    }
  }

}
