// Main Navigation
// 1. Settings
// 2. Navigation Item Hooks
// 3. Main Navigation Styles
// 4. Dropdown Navigation Styles
// 5. Main and Dropdown Navigation Hooks
// 6. Dropdown Navigation Animation
// External Variables
$site-logo-offset: 200px !default;
$site-logo-offset-fixed: 200px !default;

// 1. Settings
// Main Navigation Options
$main-nav-width-max: $content-area-width !default;
$main-nav-height: 55px !default;
$main-nav-height-fixed: 45px !default;
$main-nav-band-background-color: #fff !default;
$main-nav-flex-alignment: flex-start !default;
$main-nav-item-padding: 10px !default;

$main-nav-cascade-styles: true !default;
$main-nav-child-indicator-cascade-styles: flase !default;
$main-nav-more-plus-buffer: true !default;
$main-nav-font-family: null !default;
$main-nav-font-size: 16px !default;
$main-nav-text-case: uppercase !default;
$main-nav-text-align: center !default;
$main-nav-text-weight: normal !default;
$main-nav-text-color: #4D4F53 !default;
$main-nav-text-color-hover: #606368 !default;
$main-nav-text-color-selected: $main-nav-text-color-hover !default;
$main-nav-text-color-disabled: slategrey !default;
$main-nav-item-background-color: transparent !default;
$main-nav-item-background-color-hover: rgba(#DDD, .5) !default;
$main-nav-item-background-color-selected: $main-nav-item-background-color-hover !default;
$main-nav-item-background-color-disabled: rgba(lightgray, .5) !default;


// Dropdown Navigation Options
$dropdown-nav-min-width: 200px !default;
$dropdown-nav-min-height: 35px !default;
$dropdown-nav-transition-distance: 20px !default;
$dropdown-nav-child-indicator-enabled: true !default;

$dropdown-nav-cascade-styles: false !default;
$dropdown-nav-child-indicator-cascade-styles: false !default;
$dropdown-nav-container-background-color: #ededed !default;
$dropdown-nav-container-border-size: 5px !default;
$dropdown-nav-container-border: $dropdown-nav-container-border-size solid tomato !default;
$dropdown-nav-text-align: left !default;
$dropdown-nav-text-case: none !default;
$dropdown-nav-text-weight: normal !default;
$dropdown-nav-font-family: null !default;
$dropdown-nav-font-size: 16px !default;
$dropdown-nav-line-height: 1.2 !default;
$dropdown-nav-text-color: $main-nav-text-color !default;
$dropdown-nav-text-color-hover: $main-nav-text-color-hover !default;
$dropdown-nav-text-color-selected: $dropdown-nav-text-color-hover !default;
$dropdown-nav-text-color-disabled: $main-nav-text-color-disabled !default;
$dropdown-nav-item-padding: $main-nav-item-padding !default;
$dropdown-nav-item-background-color: transparent !default;
$dropdown-nav-item-background-color-hover: $main-nav-item-background-color-hover !default;
$dropdown-nav-item-background-color-selected: $dropdown-nav-item-background-color-hover !default;
$dropdown-nav-item-background-color-disabled: $main-nav-item-background-color-disabled !default;

$dropdown-nav-2-container-background-color: null !default;
$dropdown-nav-2-container-border-size: null !default;
$dropdown-nav-2-container-border: null !default;
$dropdown-nav-2-text-align: null !default;
$dropdown-nav-2-text-case: null !default;
$dropdown-nav-2-text-weight: null !default;
$dropdown-nav-2-font-family: null !default;
$dropdown-nav-2-font-size: null !default;
$dropdown-nav-2-line-height: null !default;
$dropdown-nav-2-text-color: null !default;
$dropdown-nav-2-text-color-hover: null !default;
$dropdown-nav-2-text-color-selected: null !default;
$dropdown-nav-2-text-color-disabled: null !default;
$dropdown-nav-2-item-background-color: null !default;
$dropdown-nav-2-item-background-color-hover: null !default;
$dropdown-nav-2-item-background-color-selected: null !default;
$dropdown-nav-2-item-background-color-disabled: null !default;


// 2. Navigation Item Hooks
// Main Nav States
@mixin main-nav-item-base() {
  & .theme-nav-link {
    color: $main-nav-text-color;
    background-color: $main-nav-item-background-color;
    transition: color $transition-linear, background-color $transition-linear;
    text-align: $main-nav-text-align;
    text-transform: $main-nav-text-case;
    font-weight: $main-nav-text-weight;
    font-size: $main-nav-font-size;
    font-family: $main-nav-font-family;
  }
}

@mixin main-nav-item-hover() {
  &:hover .theme-nav-link,
  &.dropdown-open .theme-nav-link {
    color: $main-nav-text-color-hover;
    background-color: $main-nav-item-background-color-hover;
  }
}

@mixin main-nav-item-selected() {
  &.selected .theme-nav-link {
    color: $main-nav-text-color-selected;
    background-color: $main-nav-item-background-color-selected;
  }
}

@mixin main-nav-item-disabled() {
  &.disabled .theme-nav-link,
  &.private .theme-nav-link {
    color: $main-nav-text-color-disabled;
    background-color: $main-nav-item-background-color-disabled;
  }
}

@mixin main-nav-child-indicator() {
  position: relative;
  &:before {
    font-family: 'fontawesome';
    content: "\f0d7";
    position: absolute;
    bottom: (($main-nav-height / 2) - ($main-nav-font-size/2) - 10px) / 2; // mid point
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    font-size: 10px;
    line-height: 1;
    color: $main-nav-text-color-hover;
    transition: color $transition-linear, bottom $transition-linear;
  }
  &:hover:before,
  &.dropdown-open:before {
    color: $dropdown-nav-text-color;
  }
  // .nav-fixed &:before {
  //   bottom: (($main-nav-height-fixed / 2) - ($main-nav-font-size / 2) - 10px) / 2; // mid point
  // }
}

@mixin main-nav-more() {
  .theme-nav-link {
    &:after {
      content: "+";
      margin-left: .7ch;
    }
  }
}

@mixin main-nav-icon() {
  & .theme-nav-link {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: $main-nav-height;
    line-height: 1.2;
    transition: color $transition-linear, background-color $transition-linear, height $transition-linear;
    text-transform: uppercase;
    &:before {
      content: "";
      font-size: 27px;
      width: 1em;
      height: 1em;
      margin-bottom: .3em;
      flex-shrink: 0;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transition: margin $transition-linear;
    }
  }
  // @at-root .has-main-nav.nav-fixed .theme-main-nav .theme-nav .theme-nav-link {
  //   height: $main-nav-height-fixed;
  //   &:before {
  //     margin-bottom: .25em;
  //   }
  // }

  &[data-title="about tspc"] .theme-nav-link:before {
    background-image: url(../images/nav_icons/about.svg);
    min-width: 1.8em;
    background-size: cover;
  }
  &[data-title="calendar"] .theme-nav-link:before {
    background-image: url(../images/nav_icons/calendar.svg);
  }
  &[data-title="registration"] .theme-nav-link:before {
    background-image: url(../images/nav_icons/registration.svg);
  }
  &[data-title="news"] .theme-nav-link:before {
    background-image: url(../images/nav_icons/news.svg);
  }
  &[data-title="faq"] .theme-nav-link:before {
    background-image: url(../images/nav_icons/faq.svg);
  }
  &[data-title="programs"] .theme-nav-link:before {
    background-image: url(../images/nav_icons/skate.svg);
	}
	&[data-title="parties"] .theme-nav-link:before {
    background-image: url(../images/nav_icons/balloons.svg);
  }
  &[data-title="search"] .theme-nav-link:before {
    background-image: url(../images/nav_icons/search.svg);
  }
  &.theme-nav-more .theme-nav-link:before {
    background-image: url(../images/nav_icons/more.svg);
	}


}

// Level 1 Dropdown
@mixin dropdown-nav-item-base() {
  & .theme-nav-link {
    color: $dropdown-nav-text-color;
    background-color: $dropdown-nav-item-background-color;
    transition: color $transition-linear, background-color $transition-linear;
    text-align: $dropdown-nav-text-align;
    text-transform: $dropdown-nav-text-case;
    font-weight: $dropdown-nav-text-weight;
    font-size: $dropdown-nav-font-size;
    font-family: $dropdown-nav-font-family;
    line-height: $dropdown-nav-line-height;
  }
}

@mixin dropdown-nav-item-hover() {
  &:hover .theme-nav-link,
  &.dropdown-open .theme-nav-link {
    color: $dropdown-nav-text-color-hover;
    background-color: $dropdown-nav-item-background-color-hover;
  }
}

@mixin dropdown-nav-item-selected() {
  &.selected .theme-nav-link {
    color: $dropdown-nav-text-color-selected;
    background-color: $dropdown-nav-item-background-color-selected;
  }
}

@mixin dropdown-nav-item-disabled() {
  &.disabled .theme-nav-link,
  &.private .theme-nav-link {
    color: $dropdown-nav-text-color-disabled;
    background-color: $dropdown-nav-item-background-color-disabled;
  }
}

@mixin dropdown-nav-child-indicator() {
  $dropdown-nav-indicator-inset: 15px;
  $dropdown-nav-indicator-font-size: 10px;
  @if $dropdown-nav-child-indicator-enabled==true {
    position: relative;
    &:before {
      font-family: 'fontawesome';
      content: "\f0da";
      position: absolute;
      top: 50%;
      right: ($dropdown-nav-indicator-inset/2) - ($dropdown-nav-indicator-font-size/3); // center this on inset
      transform: translate(-50%, -50%);
      display: block;
      font-size: $dropdown-nav-indicator-font-size;
      line-height: 1;
      color: $dropdown-nav-text-color-hover;
      transition: color $transition-linear;
    }
    .theme-nav-link {
      padding-right: $dropdown-nav-indicator-inset;
    }
    &:hover:before,
    &.dropdown-open:before {
      color: $dropdown-nav-text-color;
    }
  }
}

// Level 2 Dropdown
@mixin dropdown-nav-2-item-base() {
  & .theme-nav-link {
    color: $dropdown-nav-2-text-color;
    background-color: $dropdown-nav-2-item-background-color;
    transition: color $transition-linear, background-color $transition-linear;
    text-align: $dropdown-nav-2-text-align;
    text-transform: $dropdown-nav-2-text-case;
    font-weight: $dropdown-nav-2-text-weight;
    font-size: $dropdown-nav-2-font-size;
    font-family: $dropdown-nav-2-font-family;
    line-height: $dropdown-nav-2-line-height;
  }
}

@mixin dropdown-nav-2-item-hover() {
  &:hover .theme-nav-link,
  &.dropdown-open .theme-nav-link {
    color: $dropdown-nav-2-text-color-hover;
    background-color: $dropdown-nav-2-item-background-color-hover;
  }
}

@mixin dropdown-nav-2-item-selected() {
  &.selected .theme-nav-link {
    color: $dropdown-nav-2-text-color-selected;
    background-color: $dropdown-nav-2-item-background-color-selected;
  }
}

@mixin dropdown-nav-2-item-disabled() {
  &.disabled .theme-nav-link,
  &.private .theme-nav-link {
    color: $dropdown-nav-2-text-color-disabled;
    background-color: $dropdown-nav-2-item-background-color-disabled;
  }
}

// 3. Main Navigation Styles
.theme-main-nav {
  position: relative;
  color: $main-nav-text-color;
  background-color: transparent; //background-color: $main-nav-band-background-color;
  //z-index: 1;
  //box-shadow: 0px 5px 5px 0px rgba(black, .25);
  pointer-events: none;
  border-bottom: 1px solid $gray-med-2; // Site Logo Adjustments
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, .2);
	display:flex;
  .has-site-logo & .theme-nav {
    transition: padding $transition-linear, line-height $transition-linear;
    padding-left: $site-logo-offset;
  }
  // .nav-fixed .has-site-logo & .theme-nav {
  //   //padding-left: $site-logo-offset-fixed;
  //   padding-left: 222px;
  // }
  @if $main-nav-more-plus-buffer==true {
    .has-main-nav .has-site-logo & .theme-nav {
      padding-right: 0;
    }
    // .has-main-nav.nav-fixed .has-site-logo & .theme-nav {
    //   padding-right: $site-logo-offset - $site-logo-offset-fixed;
    // }
  } // Shared Main/Dropdown Navigation Styles
  .theme-nav-item {
    position: relative;
    height: 100%; // Maybe place nav height here scoped to main nav
		pointer-events: all;
		//flex: 1 1 0;
    @if $main-nav-cascade-styles==true {
      @include main-nav-item-base;
      @include main-nav-item-hover;
      @include main-nav-item-selected;
      @include main-nav-item-disabled;
    }
    @if $main-nav-child-indicator-cascade-styles==true {
      &.hasChild {
        // @include main-nav-child-indicator;
      }
    }
    &.theme-nav-more {
      // @include main-nav-more;
      @include main-nav-icon;
    }

  }
  .theme-nav-link {
    width: 100%;
    display: block; // inline-block; inline ads an extra pixel to this component for some reason :/ - Ben
  } // Main Navigation Styles
  .theme-nav {
    //max-width: $content-area-width;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: $main-nav-flex-alignment;
    list-style: none;
    line-height: $main-nav-height;
    transition: line-height $transition-linear;
    .theme-nav-link {
      white-space: nowrap;
			padding: 0 $main-nav-item-padding;
			min-width:135px;
    }
    .theme-nav-item {
      &:before {
        content: '';
        display: block;
        border-left: 1px solid $gray-med;
        position: absolute;
        left: 0;
        top: calc(50% - 30px / 2);
        height: 30px;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        width: .1%;
        border-bottom: 5px solid transparent;
        transition: width .2s linear, left .2s linear;
      }
      &.selected:after,
      &.dropdown-open:after {
        left: 0;
        width: 100%;
        border-bottom: 5px solid $primary-color;
      }
      &:hover:after {
        left: 0;
        width: 100%;
        border-bottom: 5px solid $hover-color;
      }
    }
  } // 4. Dropdown Navigation Styles
  .theme-nav-dropdown {
    >.theme-nav-item {
      @if $dropdown-nav-cascade-styles==true {
        @include dropdown-nav-item-base;
        @include dropdown-nav-item-hover;
        @include dropdown-nav-item-selected;
        @include dropdown-nav-item-disabled;
      }
      @if $dropdown-nav-child-indicator-cascade-styles==true {
        &.hasChild {
          @include dropdown-nav-child-indicator;
        }
      }
    } // Dropdown Level 1 Container Styles
    &[data-nav-level="2"] {
      background-color: $dropdown-nav-container-background-color;
      border-top: $dropdown-nav-container-border;
    } // Dropdown Level 2 Container Styles
    &[data-nav-level="3"] {
      background-color: $dropdown-nav-2-container-background-color;
      border-top: $dropdown-nav-2-container-border;
    } // Dropdown Item Styles
    .theme-nav-link {
      padding: (($dropdown-nav-min-height - ($dropdown-nav-font-size * $dropdown-nav-line-height)) / 2) $dropdown-nav-item-padding;
    }
  } // 5. Main and Dropdown Navigation Hooks
  // Main Navigation
  [data-nav-level="1"] .theme-nav-item {
    @if $main-nav-cascade-styles !=true {
      @include main-nav-item-base;
      @include main-nav-item-hover;
      @include main-nav-item-selected;
      @include main-nav-item-disabled;
    }
    @if $main-nav-child-indicator-cascade-styles !=true {
      &.hasChild {
        // @include main-nav-child-indicator;
      }
    }
    @include main-nav-icon;
  } // Dropdown Level 1
  [data-nav-level="2"] .theme-nav-item {
    @if $dropdown-nav-cascade-styles !=true {
      @include dropdown-nav-item-base;
      @include dropdown-nav-item-hover;
      @include dropdown-nav-item-selected;
      @include dropdown-nav-item-disabled;
    }
    @if $dropdown-nav-child-indicator-cascade-styles !=true {
      &.hasChild {
        @include dropdown-nav-child-indicator;
      }
    }
  } // Dropdown Level 2
  [data-nav-level="3"] .theme-nav-item {
    @include dropdown-nav-2-item-base;
    @include dropdown-nav-2-item-hover;
    @include dropdown-nav-2-item-selected;
    @include dropdown-nav-2-item-disabled;
  }
}


// 6. Dropdown Navigation Animation
.theme-main-nav {
  // Dropdown Shared
  .theme-nav-dropdown {
		list-style-type:none;
    min-width: $dropdown-nav-min-width;
    transition: opacity $transition-linear, margin $transition-linear;
    width: 100%;
    position: absolute;
    display: none;
    z-index: 1; // Dropdown Level 1
    &[data-nav-level="2"] {
      &.dropdown-opening {
        margin-top: -$dropdown-nav-transition-distance;
      }
      &.dropdown-open {
        margin-top: 0;
      }
    } // Dropdown Level 2
    &[data-nav-level="3"] {
      margin-top: -$dropdown-nav-container-border-size;
      &.dropdown-opening {
        margin-left: -$dropdown-nav-transition-distance;
      }
      &.dropdown-open {
        margin-left: 0;
      }
    } // Dropdown Level 1 & 2 Shared (Here for specificity)
    &[data-nav-level="2"],
    &[data-nav-level="3"] {
      &.dropdown-opening {
        display: block;
        opacity: 0;
      }
      &.dropdown-open {
        display: block;
        opacity: 1;
      }
    }
  }
}


// Nav extras
@mixin nav-link-item-style {
  color: $main-nav-text-color;
  background-color: $main-nav-item-background-color;
  text-align: $main-nav-text-align;
  text-transform: $main-nav-text-case;
  font-weight: $main-nav-text-weight;
  font-size: $main-nav-font-size;
  font-family: $main-nav-font-family;
  white-space: nowrap;
  padding: 0 $main-nav-item-padding;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: $main-nav-height;
  line-height: 1.2;
  cursor: pointer;
  transition: color $transition-linear, background-color $transition-linear, height $transition-linear;
  &:hover {
    color: $main-nav-text-color-hover;
    background-color: $main-nav-item-background-color-hover;
  }
  &:before {
    content: "";
    font-size: 27px;
    width: 1em;
    height: 1em;
    margin-bottom: .3em;
    flex-shrink: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: margin $transition-linear;
  }
  // .has-main-nav.nav-fixed & {
  //   height: $main-nav-height-fixed;
  //   &:before {
  //     margin-bottom: .25em;
  //   }
  // }
}

.theme-main-nav {
  .theme-nav {
    html:not(.page-manager-visible) & {
      @media screen and (min-width: $content-area-width) {
        //max-width: calc(100% - (50% - #{$main-nav-width-max/2}));
        width: 100%; //margin-left: calc(50% + 60px - #{$main-nav-width-max/2});
        margin-left: 0;
      }
    }
    .page-manager-visible & {
      @media screen and (min-width: #{$main-nav-width-max + $page-manager-width}) {
        //max-width: calc(100% - (50% - #{$main-nav-width-max/2}));
        width: 100%; //margin-left: calc(50% - #{$main-nav-width-max/2});
        margin-left: 0;
      }
    }
  }
  .main-nav-item {
    position: relative;
    height: 100%;
    order: 1;
    display: flex;
    flex-flow: row;
		pointer-events: all;
		justify-content: center;
		//flex: 1 1 0;
    &:before {
      content: '';
      display: block;
      border-left: 1px solid $gray-med;
      position: absolute;
      left: 0;
      top: calc(50% - 30px / 2);
      height: 30px;
    }
    &:after {
      content: '';
      display: block;
      border-left: 1px solid $gray-med;
      position: absolute;
      right: 0;
      top: calc(50% - 30px / 2);
      height: 30px;
    }

    @if $main-nav-more-plus-buffer==true {
      transition: margin $transition-linear;
      margin-right: 0;
      // .nav-fixed & {
      //   margin-right: $site-logo-offset-fixed - $site-logo-offset;
      // }
    }



    .theme-search-input,
    .theme-search-clear {
      display: none;
    }
    .theme-search-submit,
    .main-nav-link {
      @include nav-link-item-style;

    }
    .theme-search-submit {
      //background-color: rgba(black, .1);
      &:hover {
        /* background-color: rgba(black, .15); */
      }
      &:before {
        background-image: url(../images/nav_icons/search.svg);
      }
    }
    .main-nav-link {
      background-color: rgba(black, .2);
      &:hover {
        background-color: rgba(black, .25);
      }
      &:before {
        background-image: url(../images/nav_icons/become.svg);
      }
    }
  }
}
