/* Single Image Element */

.heroPhotoElement {
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .cutline {
    margin: 0;
    width: 100%;
  }
}

.custom-hero-image{
  .heroPhotoElement{
    display: flex;
    justify-content: center;
    align-items: center;
    @include aspect-ratio(3,1);
    &:after{
      content: "";
      position: absolute;
      top: 0; bottom: 0; left: 0; right: 0;
      display: block;
      background-image: linear-gradient(to top, rgba(black, 1) 0%, rgba(black, 0) 35%);
      z-index: 1;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
    }
    br{ display: none; }
    .cutline{
      font-size: 100px;
      @media screen and (max-width: $breakpoint-sm-max){
        font-size: 44px;
      }
      @media screen and (max-width: #{$breakpoint-sm-max/2}){
        font-size: 24px;
      }
      font-style: normal;
      font-weight: 900;
      line-height: 1.2;
      color: #FFF;
      background-color: transparent;
      text-align: center;
      z-index: 2;
      &:before,
      &:after{
        content: '';
        width: 100%;
        max-width: 5.2em; // 300px;
        height: 2px;
        background-color: $primary-color;
        display: block;
        margin: auto;
      }
      &:before{
        margin-bottom: .1em;
      }
      &:after{
        margin-top: .2em;
      }
    }
  }
}
