.user_mode {
  #yieldContent {
    .theme-sub-nav {
      font-family: $fontOne;
      background: #fff;
      padding: 10px calc( (100% - 1280px) / 2);
      [data-nav-level="1"] .theme-nav-item.selected .theme-nav-link {
        color: $primary-color;
        font-weight: normal;
      }
    }
  }
}
