.has-mobile-nav {
  .user_mode {
    .home-ctas {
      .column {
        display: flex;
        flex-flow: column;
        padding: 0;
      }
      .pageEl {
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        position: relative;
        margin-bottom: 1px;
        height: 80px;
        transition: height .2s linear;
        &:after {
          content: '';
          background-color: #555;
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }
        &.cta-expand {
          height: 335px;
          .textBlockElement .text a {
            font-size: 18px;
            line-height: 35px;
          }
        }

      }
      .textBlockElement [class$="TextImage"] {
        width: initial !important;
        img {
          display: none;
        }
      }
      .textBlockElement {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 80px;
        position: relative;
        z-index: 1;

        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          margin: 0;
          height: 80px;
          transition: all .3s ease-in-out;
          span {
            font-family: $font-secondary;
            font-size: 26px;
            line-height: 26px;
            text-transform: uppercase;
            color: #fff;
            font-style: normal;
          }
        }
        .text {
          padding: 0 15px 20px;
          a {
            font-family: $font-primary;
            font-size: 14px;
            color: #fff;
          }
        }
        &:hover {
          h3 {
            margin-top: 0;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

.has-main-nav {
  .user_mode {
    .home-ctas {
      margin-left: 1px;
      width: calc(100% - 1px);
      background: #fff;
      .column {
        display: flex;
        flex-flow: row nowrap;
        padding: 0;
      }
      .pageEl {
        flex-grow: 1;
        flex-basis: 0;
        margin: 1px 1px 1px 0;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        position: relative;
        height: 240px;
        max-height: 240px;
        &:after {
          content: '';
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, .85) 100%);
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }
        &.cta-no-image {
          .textBlockElement h3 {
            margin-top: 0;
            background-color: $primary-color;
            padding-bottom: 10px;
          }
        }
      }
      .textBlockElement [class$="TextImage"] {
        width: initial !important;
        img {
          display: none;
        }
      }
      .textBlockElement {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 240px;
        position: relative;
        z-index: 1;

        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 30px;
          padding-bottom: 30px;
          margin-top: 170px;
          margin-bottom: 0;
          transition: all .3s ease-in-out;
          text-align: center !important;
          span {
            font-family: $font-secondary;
            font-size: 22px;
            line-height: 22px;
            text-transform: uppercase;
            color: #fff;
            font-style: normal;
          }
        }
        .text {
          background-color: $primary-color;
          padding: 0 15px 100px;
          a {
            font-family: $font-primary;
            font-size: 14px;
            color: #fff;
          }
        }
        &:hover {
          h3 {
            margin-top: 0;
            background-color: $primary-color;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
