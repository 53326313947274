.has-main-nav {
  .user_mode {
    .single-cta {
      flex-grow: 1;
      flex-basis: 0;
      margin: 1px 1px 1px 0;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      position: relative;
      height: 240px;
			max-height: 240px;
			margin-top: 15px;
      &:after {
        content: '';
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, .85) 100%);
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      }
      &.cta-no-image {
        .textBlockElement h3 {
          margin-top: 0;
          background-color: $primary-color;
          padding-bottom: 10px;
        }
      }

      .textBlockElement [class$="TextImage"] {
        width: initial !important;
        img {
          display: none;
        }
      }
      .textBlockElement {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 240px;
        position: relative;
        z-index: 1;

        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 15px;
          padding-bottom: 30px;
          margin-top: 170px;
          margin-bottom: 0;
          transition: all .3s ease-in-out;
          span {
            font-family: $font-secondary;
            font-size: 26px;
            line-height: 26px;
            text-transform: uppercase;
            color: #fff;
            font-style: normal;
          }
        }
        .text {
          background-color: $primary-color;
          padding: 0 15px 100px;
          text-align: center;
          a {
            font-family: $font-primary;
            font-size: 14px;
            color: #fff;
          }
          ul {
            list-style-type: none;
          }
        }
        &:hover {
          h3 {
            margin-top: 0;
            background-color: $primary-color;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

.has-mobile-nav {
  .user_mode {
    .single-cta {
      flex-grow: 1;
      flex-basis: 0;
      margin: 1px 1px 1px 0;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      position: relative;
      height: 240px;
			max-height: 240px;
			margin-top: 15px;
      &:after {
        content: '';
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, .85) 100%);
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      }
      &.cta-no-image {
        .textBlockElement h3 {
          margin-top: 0;
          background-color: $primary-color;
          padding-bottom: 10px;
        }
      }

      .textBlockElement [class$="TextImage"] {
        width: initial !important;
        img {
          display: none;
        }
      }
      .textBlockElement {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 240px;
        position: relative;
        z-index: 1;

        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 15px;
          padding-bottom: 30px;
          margin-top: 170px;
          margin-bottom: 0;
          transition: all .3s ease-in-out;
          span {
            font-family: $font-secondary;
            font-size: 26px;
            line-height: 26px;
            text-transform: uppercase;
            color: #fff;
            font-style: normal;
          }
        }
        .text {
          background-color: $primary-color;
          padding: 0 15px 100px;
          text-align: center;
          a {
            font-family: $font-primary;
            font-size: 14px;
            color: #fff;
          }
          ul {
            list-style-type: none;
          }
        }
        &:hover {
          h3 {
            margin-top: 0;
            background-color: $primary-color;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
