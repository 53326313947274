.home {
  .siteContainer {
    padding: 0;
  }
  &.user_mode {
    .layoutContainer {
      background-color: transparent;
    }
    #panelTwo {
      padding-top: 0;
      padding-bottom: 0;
      &:before {
        display: none;
      }

    }

  }
  .pageElement {
    margin-top: 17px;
    margin-bottom: 17px;
  }
}
