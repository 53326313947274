.user_mode {
  .logos-container {
    padding-bottom: 30px;
    .column {
      align-items: center;
      .pageEl {
        &.band-header {
          flex-basis: 100% !important;
        }
        img {
          height: auto;
          width: auto;
          max-width: 60%;
          max-height: 110px;
          opacity: .4;
          filter: grayscale(100%);
          transition: filter .2s linear, opacity .4s linear;
          &:hover {
            filter: grayscale(0);
            opacity: 1;
          }
        }
      }
    }
  }
}

.has-mobile-nav {
  .user_mode {
    .logos-container {
      .column {
        flex-flow: column;
        .pageEl {
          padding-top: 10px;
          padding-bottom: 10px;
          img {
            max-width: 75%;
            max-height: 150px;
          }
        }
      }
    }
  }
}
