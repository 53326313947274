/* News Aggregator */

// MAKE LIQUID!!!!
// 1. Mixins
// 2. Global Styles
// 3. Expanded View
// 4. Condensed View
// 5. Comment Count and Read More
$pe-news-agg-title-font-family: null !default;
$pe-news-agg-title-font-color: $gray-dark !default;
$pe-news-agg-title-font-color-hover: null !default;
$pe-news-agg-title-font-size: 18px !default;
$pe-news-agg-title-font-weight: 700 !default;
$pe-news-agg-title-line-height: null !default;
$pe-news-agg-title-text-case: null !default;
$pe-news-agg-title-text-align: null !default;


// 1. Mixins (Remove when liquid)
@mixin newsagg-header {
  font-size: $pe-news-agg-title-font-size;
  font-weight: $pe-news-agg-title-font-weight;
  text-transform: $pe-news-agg-title-text-case;
  line-height: $pe-news-agg-title-line-height;
  font-family: $pe-news-agg-title-font-family;
  color: $pe-news-agg-title-font-color;
  font-size: $pe-news-agg-title-font-size;
  font-weight: $pe-news-agg-title-font-weight;
  line-height: $pe-news-agg-title-line-height;
  text-transform: $pe-news-agg-title-text-case;
  text-align: $pe-news-agg-title-text-align;
  &:hover {
    color: $pe-news-agg-title-font-color-hover;
  }

}

@mixin newsagg-image {
  display: inline-block;
  float: left;
  padding: 3px 15px 7px 0px;
  img {
    width: auto;
    display: block;
  }
}

@mixin newsagg-date-author {
  color: #666;
  font-size: em(12);
  line-height: 1.25;
  display: inline-block;
  margin-right: .5em;
}

@mixin newsagg-teaser {
  font-family: $fontOne;
  font-size: 1em;
  font-weight: 500;
  //text-transform: uppercase;
  line-height: 1.25;
  color: $bodyCopyColorTwo;
  margin: .75em 0;
}

// 2. Global styles
.newsAggregatorElement {


  .item {
    @include list-item();
  }
  .dateAuthor {
    line-height: 1;
		padding-top: 5px;
		.has-mobile-nav & {
			display:none;
		}
  }
  .newsAuthor,
  .newsDate {
    @include newsagg-date-author;
  }
  .articleHasImage {
    >h4,
    .newsText,
    .commentsAndMore,
    .newsItemHeader {
      padding-left: 90px;
    }
    .newsItemHeader {
      position: relative;
      >a {
        position: absolute;
        top: -2px;
        left: 0;
      }
    }
  }
}

//
.aggHeader {
  @include special-header;
}

// 3. Expanded Previews
.expanded {
  .newsItemHeader>a {
    @include newsagg-image;
  }
  .newsItemElement {
    h4 {
      @include newsagg-teaser;
    }
  }
  .newsItemHeader {
    h2 {
      @include newsagg-header;
    }
  }
  .newsText {
    clear: both;
    margin-top: 1em;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: none;
      font-weight: 600;
      font-size: 1em;
      letter-spacing: normal;
      margin: 0 0 .5em 0;
      padding: 0;
    }
    ul {
      list-style-type: disc;
    }
  }
}

// 4. Condensed Previews
.condensed {
  li {
    line-height: 1;
  }
  >a {
    .newsAggregatorElement & {
      @include newsagg-image;
    }
  }
  .teaser {
    @include newsagg-teaser;
  }
  .details,
  >ul {
    list-style-type: none;
    h4>a {
      @include newsagg-header;
      &:hover {
        color: $hover-color;
      }
    }
  }
  .details {
    padding-left: 90px;
  }
}

// 5. Read more and comments
.commentCount {
  .button-commentCount {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    line-height: 1;
    &:hover {
      background: none;
    }
    .button-image-right {
      display: none;
    }
    .button-content {
      margin: 0;
      padding: 0;
      position: relative;
      border: none;
      background: none;
      color: $link-color;
      font-weight: 600;
      top: 2px;
      transition: all $transition-standard;
      &:hover {
        background: none;
        border: none;
        color: $hover-color;
      }
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        font-family: FontAwesome;
        line-height: 1;
        content: "\f0e5";
        top: -1px;
        right: 0px;
        padding-right: .25em;
      }
    }
  }
}

.commentsAndMore {
  line-height: 1;
  margin: 5px 0 0 0;
  .readMore {
    display: inline-block;
    margin-right: .5em;
    a {
      margin: 0;
    }
  }
}

// 7. Responsive
@include page-element-break(400) {
  .newsAggregatorElement {
    .articleHasImage {
      >h4,
      .newsText,
      .commentsAndMore,
      .newsItemHeader {
        padding-left: 0;
      }
      .newsItemHeader {
        >a {
          position: relative;
          top: initial;
          left: initial;
        }
      }
    }
    .item>a {
      position: relative;
      top: initial;
      left: initial;
    }
    .condensed .details {
      padding-left: 0;
    }
  }
}

@include page-element-break(300) {
  .newsAggregatorElement {
    .articleHasImage {
      >h4,
      .newsText,
      .commentsAndMore,
      .newsItemHeader {
        padding-left: 0;
      }
      .newsItemHeader {
        >a {
          position: relative;
          float: none;
        }
      }
    }
    .item>a {
      position: relative;
      float: none;
    }
    .condensed .details {
      padding-left: 0;
    }
  }
}

// Hoping to get rid of these gross mqs when we figure out our breakpoints and fully implement bootstrap
// Until then, these edge cases will have to be defined outside of the "page-element-break" mixin
@media only screen and (min-width: 769px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-3,
    .col-md-6 {
      .newsAggregatorElement {
        .articleHasImage {
          >h4,
          .newsText,
          .commentsAndMore,
          .newsItemHeader {
            padding-left: 90px;
          }
          .newsItemHeader {
            >a {
              position: absolute;
              top: -2px;
              left: 0;
            }
          }
        }
        .condensed>a {
          display: inline-block;
          float: left;
          padding: 7px 15px 7px 0px;
        }
        .condensed .details {
          padding-left: 90px;
        }
      }
    }
  }
  .layout-25-50-25 {
    .col-md-3,
    .col-md-6 {
      .newsAggregatorElement {
        .articleHasImage {
          >h4,
          .newsText,
          .commentsAndMore,
          .newsItemHeader {
            padding-left: 0;
          }
          .newsItemHeader {
            >a {
              position: relative;
              display: block;
              float: none;
            }
          }
        }
        .condensed>a {
          display: block;
          float: none;
        }
        .condensed .details {
          padding-left: 0;
        }
      }
    }
  }
}



.user_mode {
  .custom-news {
    .newsAggregatorElement {
      padding: 0 20px;
      background-color: #fff;
      .item {
        position: relative;
        padding: 15px 0 5px 0;
        border: 0;
        &:after {
          content: '';
          display: block;
          border-bottom: 1px solid $border-color-theme;
          width: calc(100% + 40px);
          position: absolute;
          left: -20px;
          bottom: 0;
        }
        .readMore {
          display: none;
        }
        &.extendedOptions {
          font-size: 12px;
          padding: 0 0 10px 0;
          a {

            &:before {
              display: none;
            }
          }
        }
        &.condensed {
          .details {
            padding-left: 125px;
            .has-mobile-nav & {
              padding-left: 0;
            }
          }
          &>a {
            display: block;
            width: 100%;
            max-width: 106px;
            height: 0;
            padding-bottom: 70px !important;
            position: relative;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
              object-position: center;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}


.has-mobile-nav {
  .user_mode {
    .custom-news {
      .newsAggregatorElement .item:not(.extendedOptions)>a {
        display: none;
      }
    }
  }
}
