.user_mode {
  .event-card {
    .textBlockElement {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: $secondary-color;
      color: #fff;
      padding: 0;
      &>h3 {
        display: none;
      }

      [class$="TextImage"] {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0;
        margin: 0;
        flex-grow: 1;
        flex-basis: 0;
        img {
          min-width: 100%;
          min-height: 100%;
          padding: 0;
          margin: 0;
        }
        &:before {
          content: '';
          position: absolute;
          top: 15px;
          left: 15px;
          height: calc(100% - 30px);
          width: calc(100% - 30px);
          border: 1px solid $primary-color;
          z-index: 1;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 0; //@include darkOverlayfromLeft();
          background: rgba(0, 0, 0, .5);
        }
      }
      .cutline {
        position: absolute;
        text-align: center;
        background: transparent;
        text-transform: uppercase;
				z-index: 10;
				top: 0;
				left:0;
				height: 100%;
				width: 100%;
				display: flex;
				flex-flow: column wrap;
				align-content: center;
				justify-content: center;
        .month {
          font-family: $fontOne;
          font-style: italic;
          text-align: center;
          font-size: 22px;
          font-weight: 700;
          color: #fff;
          line-height: .9;
          background: transparent;
          margin: 0;
          display: block;
        }
        .date {
          font-family: $fontTwo;
          font-weight: 700;
          font-style: normal;
          font-size: 90px;
          line-height: 1;
          position: relative;
          color: #fff;
          &:after {
            content: '';
            position: absolute;
						width: 30%;
						left: 35%;
						bottom: -9px;
						border-bottom: 7px solid $primary-color;
          }
        }

      }
      .text {
        flex-grow: 1;
        flex-basis: 0;
        font-size: 14px;
        color: #fff;
        padding: 15px 30px;
        h3 {
          font-family: $fontTwo;
          font-size: 22px;
          color: #fff;
          font-style: normal;
          text-transform: uppercase;
        }
        p {
          text-align: center;
          padding-bottom: 5px;

        }
        a:last-of-type {

          @include global-button-style;
          @include global-link-container(12px 30px 12px 25px);
          @include global-link-text;
          display: inline-block;
          text-align: center;
        }
      }
    }
  }
}

.has-mobile-nav {
  .user_mode {
    .event-card {
      .textBlockElement {
        flex-flow: column wrap;
        width: 100% !important;
        height: auto !important;
        [class$="TextImage"] {
          width: 100% !important;
          min-height: 211px !important;
          img {
            min-height: 211px;
          }
        }
        .cutline {

					.month {

					}
					.date:after {

					}

        }
      }
      .text {
				padding: 30px;
				h3 {
					line-height:1.2;
				}
      }
    }
  }
}


.user_mode {
  .event-card-sidebar {
    margin-top: 15px;
    .textBlockElement {
      display: flex;
      flex-flow: column wrap; //align-items: center;
      justify-content: center;
      position: relative;
      background-color: $secondary-color;
      color: #fff;
      padding: 0;
      width: 100% !important;
      height: auto !important;
      min-height: 211px;

      &>h3 {
        display: none;
      }

      [class$="TextImage"] {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0;
        margin: 0;
        flex-grow: 1;
        flex-basis: 0;
        width: 100% !important;
        min-height: 211px !important;
        img {
          min-width: 100%;
          min-height: 211px;
          padding: 0;
          margin: 0;
        }
        &:before {
          content: '';
          position: absolute;
          top: 15px;
          left: 15px;
          height: calc(100% - 30px);
          width: calc(100% - 30px);
          border: 1px solid $primary-color;
          z-index: 1;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 0; //@include darkOverlayfromLeft();
          background: rgba(0, 0, 0, .5);
        }
      }
      .cutline {
        position: absolute;
        text-align: center;
        background: transparent;
        text-transform: uppercase;
        top: 40px;
        left: 0;
        z-index: 10;
        .month {
          font-family: $fontOne;
          font-style: italic;
          text-align: center;
          font-size: 22px;
          font-weight: 700;
          color: #fff;
          line-height: .9;
          top: 0;
          background: transparent;
          margin: 0;
          display: block;
        }
        .date {
          font-family: $fontTwo;
          font-weight: 700;
          font-style: normal;
          font-size: 90px;
          line-height: 1;
          position: relative;
          color: #fff;
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 10px;
            border-bottom: 7px solid $primary-color;
          }
        }

      }
      .text {
        //flex-grow: 1;
        //flex-basis: 0;
        font-size: 14px;
        color: #fff;
        padding: 30px;
        max-width: 100%;
        h3 {
          font-family: $fontTwo;
          font-size: 22px;
          color: #fff;
          font-style: normal;
          text-transform: uppercase;
        }
        p {
          text-align: center;
          padding-bottom: 5px;

        }
        a:last-of-type {
          display: initial;
          @include global-button-style;
          @include global-link-container(12px 30px 12px 25px);
          @include global-link-text;
          display: inline-block;
          text-align: center;
        }
      }
    }
  }
}
