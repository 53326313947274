.hide-teaser{
  .condensed .teaser,
  .expanded .newsItemHeader + h4{ display: none; }
}
.hide-preview-text{
  .expanded .newsText{ display: none; }
}
.hide-comments{
  .condensed .commentsAndMore,
  .expanded .commentsAndMore{ display: none; }
}
.hide-date-author{
  .condensed .dateAuthor,
  .expanded .dateAuthor{ display: none; }
}
.hide-author{
  .condensed .newsAuthor,
  .expanded .newsAuthor{ display: none; }
}
.hide-date{
  .condensed .newsDate,
  .expanded .newsDate{ display: none; }
}
.hide-preview-image{
  .condensed > a:first-of-type { display: none; }
  .condensed{
    .details,
    > ul{
      padding-left: 0;
    }
  }
  .expanded .newsItemHeader > a:first-of-type{
    display: none;
  }
  .expanded .articleHasImage > h4,
  .expanded .articleHasImage .newsText,
  .expanded .articleHasImage .commentsAndMore,
  .expanded .articleHasImage .newsItemHeader{
    padding-left: 0;
  }
}
.hide-element-options{
  .extendedOptions{ display: none; }
}
.hide-element-options-icons{
  .extendedOptions a:before{ display: none; }
}


$custom-agg-outset-margin: 5px;
$custom-agg-inset-padding: 10px;

// Custom
.custom-agg-news-header,
.custom-agg-news-only{
  @extend .hide-teaser;
  @extend .hide-preview-text;
  @extend .hide-comments;
  @extend .hide-author;

  .aggHeader{
    position: absolute;
    margin: 0 $custom-agg-outset-margin 5px $custom-agg-outset-margin;
    background-color: gray;
    z-index: 1;
    color: #FFF;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    padding: $custom-agg-outset-margin $custom-agg-inset-padding;
    &:after{ display: none; }
  }

  .newsAggregatorElement .condensed{
    display: flex;
    flex-flow: column;
    background-color: #FFF;
    padding: 0;
  }
  .newsAggregatorElement .condensed > a{ display: none; }
  .newsAggregatorElement .condensed:nth-of-type(1) > a{
    display: block;
    padding: 0;
    width: 100%;
    float: none;
    position: relative;
    @include aspect-ratio(16,9);
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
    &:after{
      content: "";
      position: absolute;
      top: 0; bottom: 0; left: 0; right: 0;
      display: block;
      background-image: linear-gradient(to top, rgba(black, 1) 0%, rgba(black, 0) 100%);
      z-index: 1;
      opacity: .5;
    }
  }
  .newsAggregatorElement .condensed .details{
    background: #FFF;
    padding: $custom-agg-inset-padding;
    margin: 0px $custom-agg-outset-margin 0px $custom-agg-outset-margin;
  }
  .newsAggregatorElement .condensed:nth-of-type(1) .details{
    margin-top: -#{$custom-agg-outset-margin * 4};
    z-index: 1;
    &:before{
      content: "News";
      color: $primary-color;
      font-size: 11px;
      display: block;
    }
  }
  .newsAggregatorElement .extendedOptions{
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0;
    background: #FFF;
  }
}
.custom-agg-news-header{
  @extend .hide-element-options;
  .newsAggregatorElement{
    margin-bottom: 0;
  }
}
.custom-agg-news-header,
.custom-agg-news-only{
  @media screen and (max-width: $breakpoint-sm-max){
    .aggHeader{
      display: inline-block;
      position: relative;
      margin: 0;
      // font-size: 20px;
    }
    .extendedOptions{ display: none; }
    .newsAggregatorElement .condensed{
      flex-flow: row;
      padding: 20px 20px; // 50px 20px;
      + .condensed{
        display: none;
      }
    }
    .newsAggregatorElement .condensed:nth-of-type(1) > a{
      max-width: 130px; // 200px;
      margin-right: 20px;
    }
    .newsAggregatorElement .condensed .details h4 > a,
    .newsAggregatorElement .condensed > ul h4 > a{
      // font-size: 36px;
    }
    .newsAggregatorElement .newsAuthor,
    .newsAggregatorElement .newsDate{
      // font-size: 22px;
      color: $primary-color;
    }
    .newsAggregatorElement .condensed .details{
      flex-grow: 1;
      padding: 10px 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    .newsAggregatorElement .condensed:nth-of-type(1) .details{
      margin: 0;
      &:before{ display: none; }
    }
  }
  @media screen and (max-width: $breakpoint-sm-max){
    .newsAggregatorElement .condensed{
      flex-flow: row wrap;
    }
  }
}
.custom-agg-news-only{
  .newsAggregatorElement .item{
    border-top-color: transparent;
    position: relative;
    &:not(.first-of-type):before{
      content: "";
      width: 100%;
      height: 2px;
      background-color: $gray-light;
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      padding: 0 15px;
      background-clip: content-box;
      box-sizing: border-box;
    }
  }
}

.custom-agg-event-body{
  .user_mode &{
    @media screen and (max-width: $breakpoint-sm-max){
      display: none;
    }
  }
  .eventAggregatorElement{
    background: #FFF;
    padding: 0 #{$custom-agg-outset-margin + $custom-agg-inset-padding};
    margin-top: 0;
    &:before{
      content: "Events";
      color: $primary-color;
      font-size: 11px;
      display: block;
      border-top: 2px solid $gray-light;
      padding-top: 15px;
    }
    .aggHeader,
    .description,
    .tags{
      display: none;
    }
    .vevent{
      &:first-of-type{
        padding-top: 5px;
      }
    }
    .extendedOptions{
      border-top: 2px solid $gray-light !important;
      padding: 15px 0;
      margin: 0;
    }
  }
}


.custom-agg-link{
  .user_mode &{
    @media screen and (min-width: $breakpoint-md-min){ display: none; }
  }
  .linkElement{
    margin-top: 0;
    padding: 20px 20px; //50px 20px;
    background-color: #FFF;
    position: relative;
    h4 a{
      display: block;
      text-align: center;
    }
    &:before{
      content: "";
      width: 100%;
      height: 2px;
      background-color: $gray-light;
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      padding: 0 20px;
      background-clip: content-box;
      box-sizing: border-box;
    }
  }
}
