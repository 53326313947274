// Site Logo
// 1. Settings
// 2. Styles


// External Variables (Un-comment these if you are having trouble with missing variables)
// $main-nav-width-max: $content-area-width !default;
// $content-area-width: $maxWidth !default;


// 1. Settings
$site-logo-size: $site-tagline-height + $main-nav-height + $sub-nav-height !default;
$site-logo-size-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed !default;
$site-logo-width-desktop: $site-logo-size !default;
$site-logo-height-desktop: $site-logo-size !default;
$site-logo-width-desktop-fixed: $site-logo-size-fixed !default;
$site-logo-height-desktop-fixed: $site-logo-size-fixed !default;
$site-logo-width-mobile: 100px !default;
$site-logo-height-mobile: 60px !default;
$site-logo-padding: 5px !default;
$site-logo-offset: $site-logo-width-desktop !default;
$site-logo-offset-fixed: $site-logo-width-desktop-fixed !default;


// 2. Styles
.has-main-nav{
  #sn-site-logo{
    position: absolute;
    width: 100%;
    max-width: $content-area-width;
    height: 0px;
    //z-index: 2;
    top: 0;
    @if $main-nav-width-max != 100% {
			//padding: 0 calc( 50% - #{$main-nav-width-max / 2} );
			padding:0;
    }

    .sn-site-logo-wrap{
      display: table;
      table-layout: fixed;
      width: $site-logo-width-desktop;
      height: $site-logo-height-desktop;
      transition: height $transition-linear, width $transition-linear, padding $transition-linear;
      padding: $site-logo-padding-desktop; // $site-logo-padding;
      box-sizing: border-box;
      @include logo-desktop-styles;
    }
    .sn-site-logo-background{
      display: table-cell;
      vertical-align: middle;
      overflow: hidden;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  // Fixed Logo Size
//   .nav-fixed{
//     #sn-site-logo{
//       .sn-site-logo-wrap{
//         //width: $site-logo-width-desktop-fixed;
//         height: $site-logo-height-desktop-fixed;
// 				//padding: $site-logo-padding-desktop-fixed;
// 				width:200px;
// 				padding: 5px 20px;
//       }
//     }
//   }
 }


.has-mobile-nav{
  .sn-site-logo-wrap{
    width: $site-logo-width-mobile;
    height: $site-logo-height-mobile;
		display: block;
		margin-top:-1px;
    @include logo-mobile-styles;
  }
  .sn-site-logo-background{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display: block;
  }
}
