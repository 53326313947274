.facebook-link,
.twitter-link,
.instagram-link {
  .linkElement {
    h4 {

      a {
        font-weight: 400;
        font-size: 16px;
        background: $secondary-color;
        text-align: left;
        position: relative;
        padding-left: 60px;
        &:before {
          content: '';
          position: absolute;
          display: inline-block;
          line-height: 20px;
          width: 40px;
          height: 20px;
          left: 0;
          top: 10px;
          font-family: "fontawesome";
          font-style: normal;
          font-size: 18px;
          color: #fff;
          border-right: 1px solid white;
          text-align: center;
          margin-right: 10px;
        }
        &:after {
          transition: padding .2s ease;

        }
        &:hover:after {
          padding-left: 20px;
        }
      }
    }
  }
}


.facebook-link {
  .linkElement {
    h4 {
      a {
        &:before {
          content: '\f09a';
        }
        &:hover {
          background: #41539F;
        }
      }
    }
  }
}


.twitter-link {
  .linkElement {
    h4 {
      a {
        &:before {
          content: '\f099';
        }
        &:hover {
          background: #409FF1;
        }
      }
    }
  }
}

.instagram-link {
  .linkElement {
    h4 {
      a {
        &:before {
          content: '\f16d';
        }
        &:hover {
          background: #C7456D;
        }
      }
    }
  }
}
