#googleTranslate {
  display: flex;
  justify-content: flex-end;
  background: #fff;
	//padding: 10px calc((100vw - 1280px)/2);
	padding: 10px 25px;
	border-bottom:1px solid $gray-med;
  //box-shadow: inset 0px -1px 3px 0px rgba(0, 0, 0, 0.3);
  #google_translate_element {
    .goog-te-gadget-simple {
      position: relative;
      min-width: 125px;
      border: 1px solid $gray-med-2;
      padding-left: 4px;
      &:after {
        content: '\f107';
        position: absolute;
        width: 17px;
        height: 15px;
        top: 2px;
        right: 0;
        font-family: "fontawesome";
        font-size: 15px;
        color: $gray-med-2;
      }
      .goog-te-gadget-icon {
        display: none;
      }
      a.goog-te-menu-value {
        img {
          display: none;
        }
        span {
          font-size: 11px;
          color: $gray-med-2;

          &:nth-child(n+2) {
            display: none;
          }
        }

      }
    }
  }

}

.has-mobile-nav {
  #googleTranslate {
    background: transparent;
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 210;
		box-shadow: none;
		border-bottom:none;
  }
}

.edit_mode,
.slide-nav-open {
  #googleTranslate {
    display: none;
  }
}
