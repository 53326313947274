.sportsPage {
  .site-background {
    background: #fff;
  }
  #panelTwo {
    max-width: 1354px;
    margin: auto;
    padding: 0 30px;
  }
  .teamLogo-tiny {
    display: none;
  }
  [id^="widget_standings_widget_"] .sportTableLabel h4.teamName,
  #tab_division_standings_content .sportTableLabel h4.teamName {
    padding-left: 0;
  }
  .yieldPageContent>#galleryList,
  .yieldPageContent>#tooltips,
  .yieldPageContent>#sport-slider,
  .yieldPageContent>.contentTabs,
  .yieldPageContent>#contentTabsLoader,
  .yieldPageContent>.rapidContentGeneral,
  .yieldPageContent>.games-slider,
  .yieldPageContent>#TeamInstanceStatsDiv,
  .yieldPageContent>.bottomOptions {
    padding: 0;
  }
}
