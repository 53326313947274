.has-main-nav {
  .user_mode {
    .custom-70-30 {
      display: flex;
      flex-flow: row nowrap;
      padding: 20px calc( (100% - 1290px) / 2);

      &>.layoutContainer {
        flex-basis: 70%;
        padding: 15px 25px 0 25px;
				overflow: hidden;
				box-sizing: border-box;
      }

      .layoutContainer.connected-template {
        flex-basis: 30%;
        padding-left: 15px;
        padding-top: 0;
				background: none;
				overflow: hidden;
				box-sizing: border-box;
        .layoutContainer {
          background: none;
				}
				.layoutContainer:first-child .column-1 .pageEl:first-child .pageElement {
					margin-top: 0;
				}

      }

      .connected-template-start,
      .connected-template-end {
        display: none;
      }
    }
  }
}

.has-mobile-nav {
  .layoutContainer.connected-template {
    padding: 0 20px;
  }
}
