.linkElement {
  margin: 0 0 10px 0;
  .user_mode .quick-link & {
    h4 {
      a {
        font-size: 18px;
        font-weight: 400;
        color: #333;
        background: #ccc;
        text-transform: none;
        box-shadow: none;
        &:after {
          display: none;
        }
        &:hover {
          background: #eee;
        }
      }
    }
  }
}

.quick-links {
  height: 100%;

  .textBlockElement {
		font-family: $fontOne;
		background-color: $secondary-color;
    h3 {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      font-style: normal;
      padding: 15px 25px;
      text-transform: none;
      border-bottom: 1px solid $gray-dark;
      margin: 0;
    }

    .text {
      ul {
        list-style-type: none;
        li {
          font-size: 14px;

          border-bottom: 1px solid $gray-dark;

          a {
            display: block;
            padding: 10px 12px;
            color: #fff;
            &:hover {
              background-color: $gray-dark;
              text-decoration: none;

            }
            &:before {
              content: '\f105';
              font-family: "FontAwesome";
              padding-right: 10px;

            }
          }
        }
      }
    }
  }
}
