.has-mobile-nav {
	.gsc-control-cse * {
		box-sizing: inherit;
	}
	.theme-search-overlay {
    // max-width: 90%;
	}
  .gsc-results-wrapper-overlay {
    left: 2% !important;
  }
}
