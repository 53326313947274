$custom-text-card-border: 2px solid $gray-light;

.custom-text-card{
  .textBlockElement{
    border: $custom-text-card-border;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .leftTextImage,
    .rightTextImage{
      margin: 0;
      float: none;
      width: 100%;
      border-bottom: $custom-text-card-border;
      &:before{
        
      }
    }
    .text{
      padding: 15px;
    }
  }
}
