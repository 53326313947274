.user_mode {
  .global-footer-header {
    background-color: #000 !important;
    padding-top: 30px;
    padding-bottom: 20px;
    .pageEl {
      position: relative;
      &:before,
      &:after {
        content: '';
        width: 40%;
        position: absolute;
        display: block;
        border-bottom: 1px solid $gray-med-2;
        top: 50%;
        left: 0;
      }
      &:after {
        left: auto;
        right: 0;
      }
    }
    img {
      max-width: 17%;
    }
  }
  .global-footer {
    background-color: #000 !important;
    color: #fff;
    padding-bottom: 30px;
    .column {
      display: flex;
      justify-content: space-between;
    }
    a,
    h3 {
      color: #fff;
      font-style: normal;
    }
    a.button-small {
      color: #fff;
      background: $primary-color;
      text-decoration: none;
      font-size: 14px;
      font-style: italic; //text-transform: uppercase;
      padding: 12px 30px 12px 25px;
      transition: background-color .2s linear;
      &:hover {
        background: $hover-color;
      }
    }
    p {
      font-size: .85em;
    }
    .pageEl h3,
    .pageEl h4 {
      font-size: 18px;
    }

  }
}


.has-mobile-nav {
  .user_mode {
    .global-footer-header {
      padding-top: 30px;
      padding-bottom: 15px;
      .pageEl {
        position: relative;
        &:before,
        &:after {
          width: 20%;
        }
      }
      img {
        min-width: 50%
      }
    }
    .global-footer {
      padding-bottom: 30px;
      .column {
        flex-flow: column;
        .pageEl {
          display: none;
          &:last-child {
            display: initial;
          }
        }
      }
      .textBlockElement,
      h3 {
        text-align: center !important;
      }
      a.button-small {
        margin-top: 15px;
      }
    }
  }
}

.snFooterContainer {
  .global-footer-header {
    #pageEl_538379940 {
      display: none;
    }
  }
}
