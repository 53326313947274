/* Document Page Element */
// 1. Element Styling
// 2. Document Icons

// 1. Element Styling
.documentElement {
  h3 {
    display: flex;
    align-items: center;
    margin: 0; // 0 0 1em 0;
    padding: 0; //15px 0 0 60px;
    font-family: $fontTwo;
    font-size: 18px;
    font-weight: 900;
    // background-repeat: no-repeat;
    // background-size: 40px;
    // background-position: left center;
    line-height: 1.2;
    text-transform: none;
    &:before{
      font-family: fontawesome;
      font-size: inherit;
      line-height: 1;
      color: $primary-color;
      padding-right: 13px;
      -webkit-font-smoothing: antialiased;
      font-weight: normal;
    }
    &.iconSizeXS{
      &:before{ font-size: 18px; }
      // background-size: 16px 16px;
      // min-height: 16px;
      // padding-left: 22px;
      // padding-top: 0;
    }
    &.iconSizeS{
      &:before{ font-size: 24px; }
      // background-size: 24px 24px;
      // min-height: 24px;
      // padding-left: 30px;
      // padding-top: 0;
    }
    &.iconSizeM{
      &:before{ font-size: 32px; }
      // background-size: 32px 32px;
      // min-height: 32px;
      // padding-left: 40px;
      // padding-top: 0;
    }
    &.iconSizeL{
      &:before{ font-size: 48px; }
      // background-size: 48px 48px;
      // min-height: 48px;
      // padding-left: 56px;
      // padding-top: 0;
    }
    &.iconSizeXL{
      &:before{ font-size: 60px; }
      // background-size: 60px 60px;
      // min-height: 60px;
      // padding-left: 68px;
      // padding-top: 0;
    }
  }
}

// 2. Document Icons
.pdf{
  // background-image:  url($asset_path_for+"/app_images/docIconPdf.svg");
  &:before{ content: "\f1c1"; }
}

.word{
  // background-image:  url($asset_path_for+"/app_images/docIconWord.svg");
  &:before{ content: "\f1c2"; }
}

.excel{
  // background-image:  url($asset_path_for+"/app_images/docIconExcel.svg");
  &:before{ content: "\f1c3"; }
}

.powerpoint{
  // background-image:  url($asset_path_for+"/app_images/docIconPpt.svg");
  &:before{ content: "\f1c4"; }
}

.iconText{
  // background-image:  url($asset_path_for+"/app_images/docIconText.svg");
  &:before{ content: "\f15c"; }
}

.imageBmp{
  // background-image:  url($asset_path_for+"/app_images/docIconBmp.svg");
  &:before{ content: "\f1c5"; }
}

.imageEps{
  // background-image:  url($asset_path_for+"/app_images/docIconEps.svg");
  &:before{ content: "\f1c5"; }
}

.imageGif{
  // background-image:  url($asset_path_for+"/app_images/docIconGif.svg");
  &:before{ content: "\f1c5"; }
}

.imageJpg{
  // background-image:  url($asset_path_for+"/app_images/docIconJpg.svg");
  &:before{ content: "\f1c5"; }
}

.imagePng{
  // background-image:  url($asset_path_for+"/app_images/docIconPng.svg");
  &:before{ content: "\f1c5"; }
}

.imageTif{
  // background-image:  url($asset_path_for+"/app_images/docIconTif.svg");
  &:before{ content: "\f1c5"; }
}

.videoAvi{
  // background-image:  url($asset_path_for+"/app_images/docIconAvi.svg");
  &:before{ content: "\f1c8"; }
}

.videoMov{
  // background-image:  url($asset_path_for+"/app_images/docIconMov.svg");
  &:before{ content: "\f1c8"; }
}

.videoMpg{
  // background-image:  url($asset_path_for+"/app_images/docIconMpg.svg");
  &:before{ content: "\f1c8"; }
}

.iconCsv{
  // background-image:  url($asset_path_for+"/app_images/docIconCsv.svg");
  &:before{ content: "\f1c9"; }
}

.audioMp3{
  // background-image:  url($asset_path_for+"/app_images/docIconMp3.svg");
  &:before{ content: "\f1c7"; }
}

.audioWav{
  // background-image:  url($asset_path_for+"/app_images/docIconWav.svg");
  &:before{ content: "\f1c7"; }
}

.zip{
  // background-image:  url($asset_path_for+"/app_images/docIconZip.svg");
  &:before{ content: "\f1c6"; }
}

.generic{
  // background-image:  url($asset_path_for+"/app_images/docIconGeneric.svg");
  &:before{ content: "\f15b"; }
}
