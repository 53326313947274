#displayBodyHeader {
  //background-color: $page-title-container-background;
  //@if $page-title-full-width != true { @include content-area-width; }
  //@include page-title-container;
  display: flex;
  align-items: center;
  padding-top: 20% !important;
	max-width: 100%;
	position: relative;
  span {
    margin-top: -20%; // display: block;
    color: $page-title-text-color;
    font-size: 24px;
    text-transform: $page-title-text-case;
    line-height: 1;
		position: relative; //margin-left: calc((100% - 1260px) / 2);
		text-shadow: 0 0 15px rgba(0,0,0,.1);
		position: relative;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      left: 2px;
      bottom: -10px;
      width: 50px;
      border-bottom: 5px solid $primary-color; //z-index: 1;
    }
  }
  &.displayBodyHeader-image {
    text-indent: 0; //height: auto !important;
    //padding-top: 0px !important;
    //@include page-title-image-container;
    .has-sub-nav & {
      margin-top: -40px;
		}

	}
	&:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    @include darkOverlayfromLeft();
  }

}

@media only screen and (min-width:768px) {
  #displayBodyHeader {
    span {
      font-size: 60px;
      &:after {
        width: 100px;
        border-bottom: 10px solid $primary-color;
      }
    }
  }
}

@media only screen and (min-width:1100px) {
  #displayBodyHeader {
    span {
      font-size: 80px;

    }
  }
}

@media only screen and (min-width:1300px) {
  #displayBodyHeader {
    span {
      font-size: 90px;
      margin-left: calc((100% - 1260px) / 2);

    }
  }
}
