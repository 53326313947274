/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// 1. Page Element Styles
.linkElement{
  h4{
    .link-center &{ text-align: center; }
    a{
      @include global-link-style;
      @include global-link-container;
      @include global-link-text;
      display: block;
			text-align: center;
			&:after {
				content: '\f105';
				font-family: "FontAwesome";
				padding-left: 6px;
		}
      .link-inline-block &{
        display: inline-block;
      }
      // @include global-link-icon-style;
      .link-secondary &{
        background-color: $gray-dark;
        font-size: 22px;
        font-weight: 900;
        padding: 11px 24px;
        display: block;
        text-align: center;
        &:hover{
          background-color: $primary-color;
        }
      }
      .link-icons &{
        background-color: $gray-dark;
        display: flex;
        flex-flow: column;
        align-items: center;
        font-size: 28px;
        font-weight: 900;
        &:after{
          content: "";
          font-size: 20px;
          width: 1em;
          height: 1em;
          margin: .25em 0;
          flex-shrink: 0;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          order: -1;
        }
      }
      .link-icons.icon-scores &:after{ background-image: url(../images/nav_icons/scores.svg); }
      .link-icons.icon-rank &:after{ background-image: url(../images/nav_icons/rank.svg); }
      .link-icons.icon-athletes &:after{ background-image: url(../images/nav_icons/athletes.svg); }
      .link-icons.icon-coaches &:after{ background-image: url(../images/nav_icons/coaches.svg); }
      .link-icons.icon-officials &:after{ background-image: url(../images/nav_icons/officials.svg); }
      .link-icons.icon-clubs &:after{ background-image: url(../images/nav_icons/clubs.svg); }
      .link-icons.icon-comp &:after{ background-image: url(../images/nav_icons/comp.svg); }
      .link-icons.icon-about &:after{ background-image: url(../images/nav_icons/about.svg); }

      .link-inline &{
        background-color: transparent;
        padding: 0;
        color: #FFF;
        font-size: 18px;
        font-weight: 400;
        &:hover{
          background-color: transparent;
          color: #FFF;
        }
        &:after{
          content: "";
          font-family: FontAwesome;
          margin-left: .5ch;
        }
      }
    }
  }
  .goToLink,
  .emailLink{
    &:before{ display:none; }
  }
  .goToLink{
    // @include global-link-icon-character;
  }
  .emailLink{
    // @include global-link-icon-character($character: "\f003"); // envelope-o
  }
  .text{
    font-size:rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement{
  h4{
    font-family:$fontOne;
    font-size:rem(18);
    font-weight:700;
    background:transparent;
    padding:10px 0 5px 0;
    &:hover{
      background:transparent;
    }
    a{
      display: inline;
      color:$link-color;
      padding: 0;
      margin-bottom: 0px;
      background:transparent;
      text-decoration:none;
      opacity: 1;
      &:hover{
        text-decoration:underline;
      }
    }
  }
  .goToLink{
    &:before,
    &:after{
      display:none;
    }
  }
  .text{
    font-size:rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}
