.edit_mode {
  // Hide the placeholder image for the missing favicon image
  .selectedNodes .nodePicker-site-name img {
    display: none;
  }
}

@mixin column-count ($columns) {
  // Use this to create custom column counts in edit mode layout containers.
  // Usage: Set layout container to single column then add one of the em-col-# classes 
  // to the containers custom classes
  //
  .pageElementAdmin {
    margin: 0 5px;
  }
  .column {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    .columnBounds {
      display: flex;
      flex-flow: row wrap;
      width: 100%;

      &>a.addPageElementTop {
        flex-basis: 100%;
      }

      &>div.drag {
				flex-basis: calc(100% / #{$columns});
				overflow: hidden;
      }
    }
  }
}

.edit_mode {
  .em-col-3 {
    @include column-count (3);
  }
  .em-col-4 {
    @include column-count (4);
  }

  .em-col-5 {
    @include column-count (5);
  }

  .em-col-6 {
    @include column-count (6);
  }

  .em-col-7 {
    @include column-count (7);
  }

  .em-col-8 {
    @include column-count (8);
  }
}
