.padding {
	$padding-desktop: 20px;
	$padding-bottom-desktop: 33px;
	$padding-desktop: 20px;
  $padding-mobile: 10px;
  padding-top: $padding-desktop;
  padding-bottom: $padding-desktop;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-top: $padding-mobile;
    padding-bottom: $padding-mobile;
  }
  &-top {
    padding-top: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: $padding-mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max){
        padding-top: $padding-mobile;
      }
    }
  }
  &-bottom {
    padding-bottom: $padding-bottom-desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-bottom: $padding-mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max){
        padding-bottom: $padding-mobile;
      }
    }
  }
}
.compact {
  &.pageEl .pageElement,
  .pageEl .pageElement {
    margin-bottom: 0;
    margin-top: 0;
  }
  &-top {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-top: 0;
    }
  }
  &-bottom {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-bottom: 0;
    }
  }
}
.layoutContainer{
  &.background{
    &-gray-dark{ background-color: $gray-dark; }
    &-gray-lt{ background-color: $gray-light; }
    &-gray-light{ background-color: $gray-light; }
    &-gray-dark{ background-color: $gray-dark; }
    &-primary{ background-color: $primary-color; }
    &-white{ background-color: #FFF; }
    &-gray-wood {
      background-color: $gray-light;
      background-image: url(../images/_background.jpg);
      background-repeat: no-repeat;
      background-position: top center;
    }
    &-gray-game {
      div:not(.connect-wrapper) > &{
        position: relative;
        background-color: #333; // $gray-dark;
        &:before{
          opacity: .1;
          content: "";
          background-image: url(../images/_game_background.jpg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          position: absolute;
          top: 0; bottom: 0; left: 0; right: 0;
          display: block;
        }
      }
    }
    &-gray-champions {
      div:not(.connect-wrapper) > &{
        position: relative;
        background-color: #333; // $gray-dark;
        background-image: url(../images/_champ_background.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }
  }
}

.header-special{
  .textBlockElement > h3{ @include global-header-line; color: $gray-dark;}
  &-white .textBlockElement > h3 { @include global-header-line; color: #FFF; }
  &-primary .textBlockElement > h3 { @include global-header-line; color: $primary-color; }
  &-gray-dark .textBlockElement > h3 { @include global-header-line; color: $gray-dark; }
  &-gray-light .textBlockElement > h3 { @include global-header-line; color: $gray-light; }
  &.header-center .textBlockElement > h3 { justify-content: center; }
}
.header-large .textBlockElement > h3 { font-size: 48px; }
.textBlockElement > h3:only-child{ margin-bottom: 0; }


.connect-wrapper{
  &.background-gray-game {
    position: relative;
    background-color: #333; // $gray-dark;
    &:before{
      opacity: .1;
      content: "";
      background-image: url(../images/_game_background.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: absolute;
      top: 0; bottom: 0; left: 0; right: 0;
      display: block;
    }
    display: flex;
    flex-flow: column;
    padding: 50px 0;
    > .layoutContainer{
      background-color: transparent;
      background-image: none;
    }
  }

  &.background-gray-champions {
    position: relative;
    background-color: #333; // $gray-dark;
    background-image: url(../images/_champ_background.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-flow: column;
    padding: 50px 0;
    > .layoutContainer{
      background-color: transparent;
      background-image: none;
    }
  }

  // .padding + &,
  // .padding-bottom + &{
  //   @media screen and (max-width: $breakpoint-sm-max){
  //     margin-top: -30px; // $padding-mobile
  //   }
  // }
}


.text > p > em > a{
  @include global-link-style;
  @include global-link-container;
  @include global-link-text;
  display: inline-block;
  font-style: normal;
  &:hover{
    text-decoration: none;
  }
}


// Home Page Countdown Area
.custom-ad-buttons{
  .user_mode &{
    display: inline-block;
    margin-right: 15px;
    + .custom-ad-buttons{
      margin-right: 0;
    }
  }
  max-width: 135px;
}
.background-gray-champions{
  .textBlockElement,
  .text h1,
  .text h2,
  .text h3,
  .text h4,
  .text h5,
  .text h6{
    color: #FFF;
  }
  .link-secondary + .link-secondary .linkElement{
    margin-top: 0;
  }
}
.text-large{
  .text{
    p{
      font-size: 18px;
    }
  }
}


// Four Columns
.force-4-columns.layoutContainer{
  // .custom-column-split{
    .hrElement{
      background: $primary-color;
      + span:before{
        content: "This element controls where this column ends and the next column begins. This element will only be visible in edit mode."
      }
    }
  // }
  .user_mode & .custom-column-split{
    display: none;
  }
}


.user_mode{
  .desktop-only{
    @media screen and (max-width: $breakpoint-sm-max){ display: none; }
    @media screen and (min-width: $breakpoint-md-min){ display: block; }
  }
  .mobile-only{
    @media screen and (max-width: $breakpoint-sm-max){ display: block; }
    @media screen and (min-width: $breakpoint-md-min){ display: none; }
  }
}
