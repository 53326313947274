// Mega Nav
$dropdown-border: 1px solid $primary-color;
$mega-nav-background-color: white;


#topNav .theme-nav-dropdown.loading {
  display: none !important;
}

#topNav {
  .theme-main-nav .theme-nav-style-dropdown~.theme-nav-dropdown[data-nav-level="2"]:not(.theme-nav-more-dropdown) {
    width: 100% !important;
    max-width: 900px;
    left: 360px !important;
    margin-left: 0;
    top: 100% !important;
    padding: 90px 10px 20px 10px;
    border: 1px solid #DBE3E5;
    background-color: $mega-nav-background-color;
		column-count: 4;
		list-style-type:none;

    &.dropdown-open {
			display: block;
			display: flex;
			flex-flow: row wrap;
    }
  }
  &.nav-fixed .theme-main-nav .theme-nav-style-dropdown~.theme-nav-dropdown[data-nav-level="2"]:not(.theme-nav-more-dropdown) {
		//padding-left: $site-logo-offset-fixed;
		padding-left:10px;
		left: 222px !important;
  }

}

#topNav .theme-main-nav .theme-nav-style-dropdown~.theme-nav-dropdown:not(.theme-nav-more-dropdown)>.theme-nav-item {
	padding: 0 5px;
	flex-basis: 25%;
	margin-bottom: 10px;
}

#topNav {
  .theme-main-nav {
    [data-nav-level="2"]:not(.theme-nav-more-dropdown)>.theme-nav-item>.theme-nav-link {
      //border-bottom: 1px solid $gray-med;
      font-family: $fontOne;
      font-size: 14px;
      font-weight: 700;
      padding-top: 5px;
			padding-bottom: 0;
			text-transform:uppercase;

    }
    .theme-nav-dropdown-children {
      list-style: none;
			margin-top: 5px;
			padding: 0 0 0 10px;
      .theme-nav-link {
        width: 100%;
        display: block;
        font-size: 13px;
        color: $gray-dark;
      }
      .theme-nav-item {
        &:hover .theme-nav-link,
        &.selected .theme-nav-link {
          color: $primary-color;
        }
        &.disabled .theme-nav-link {}
        &.disabled:hover .theme-nav-link {}
      }
    }
    .dropdown-nav-title {
      font-size: 20px;
      font-weight: 700;
      font-style: italic;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      left: 25px;
      width: calc(100% - 50px);
      border-bottom: 1px solid #DBE3E5;;
      padding: 30px 0 4px 0;
      &:after {
        content: '';
        width: 50px;
        position: absolute;
        left: 0;
        bottom: -2px;
        border-bottom: 5px solid $primary-color;
      }
    }
  }
}

// More +
.theme-main-nav {
  .theme-nav-dropdown[data-nav-level="2"].theme-nav-more-dropdown {
    background-color: $gray-light;
    padding: 10px;
		z-index: 3;
		.dropdown-nav-title {
			display:none;
		}
  }
}

// Mega Nav - hide on more+ active
.theme-nav-more-dropdown.dropdown-open~.theme-nav-dropdown {
  display: none !important;
}
