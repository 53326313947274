.user_mode {
  .wide-cta {
    .sn-call-to-action {
      font-style: italic;
      text-transform: uppercase;
      max-height: 212px;
      &:before,
      &:after {
        display: none;
      }
      img {
        filter: brightness(55%);
        transition: filter .2s linear;
      }
      &:hover {
        img {
          filter: brightness(45%);
        }
      }
    }
  }
}
